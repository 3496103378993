import config from '/config';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

const computeURL = (platform, title, initialURL = false) => {
	const url = (config.siteURL + (initialURL ?? ''));

	switch(platform) {
		case 'x':
			return `https://x.com/intent/post?url=${encodeURI(url)}&text=${title}`;
		case 'facebook':
			return `https://facebook.com/sharer/sharer.php?u=${url}${title ? ('&quote=' + title) : ''}`;
		case 'whatsapp':
			return `https://api.whatsapp.com/send?text=${title ? (title + '\n') : ''} \n${url}`;
		default:
			return false;
	}
}

const SocialShareBtn = ({ platform, initialURL, title, className, children }) => {
	const linkUrl = useMemo(() => computeURL(platform, title, initialURL), []);

	if(linkUrl) {
		return (
			<a
				className={className}
				target="_blank"
				rel="noopener noreferrer"
				href={linkUrl}>
				{children}
			</a>
		)
	}
	else {
		return false;
	}
}

SocialShareBtn.defaultProps = {
	className: '',
	title: false,
	initialURL: false
}

SocialShareBtn.propTypes = {
	platform: PropTypes.string,
	title: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
	initialURL: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.string]),
	className: PropTypes.string,
	children: PropTypes.node,
}

export default SocialShareBtn