import '/assets/styles/pages/product-detail.scss';
import config from '/config';
import { useState, useEffect, useRef, useMemo } from 'react'
import loadable from '@loadable/component'

// Deps
import kebabCase from 'lodash/kebabCase'
import differenceInDays from 'date-fns/differenceInDays'
import differenceInHours from 'date-fns/differenceInHours'

// Functions
import formatDate from '/functions/format-date';
import scrollToElement from '/functions/scroll-to-element'

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Loader from '/views/partials/loader'
import Slider from '/views/partials/slider'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Pricebox from '/views/partials/pricebox'
import Btn from '/views/partials/btn'
import { Form as InputForm } from '/views/partials/forms'
import RatingBox from '/views/partials/rating-box'
import Tabs from '/views/partials/tabs'
import Placeholder from '/views/partials/placeholder'
import SocialShareBtn from '/views/partials/social-share-btn'
import EmptyMessage from '/views/partials/empty-message'
import Collapser from '/views/partials/collapser'
import ProductInstallmentOptions from '/views/partials/product-installment-options'

// Sections
import Breadcrumbs from '/views/sections/breadcrumbs'
import SiteFeatures from '/views/sections/site-features'
// import CategoryTopProducts from 'views/sections/category-top-products'

// Services
import productServices from '/services/product'
import siteServices from '/services/site'

// Context
import { useBasketProduct, useBasketProductControls } from '/controllers/basket';
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useBreakpoints } from '@wearetla/tla-essentials-tools/utilities/breakpoints';
import { useModals } from '@wearetla/tla-essentials-tools/utilities/modals';
import { useHead } from '@wearetla/tla-essentials-tools/utilities/head';
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator'
import  { getRoutePath as getRoutePathFunction } from '@wearetla/tla-essentials-tools/utilities/navigator/functions/get-route-path'
import { useWishlist } from '/controllers/wishlist';
import { useAnalytics, parseAnalyticsProduct } from '/utilities/analytics';

const ProductCarousel = loadable(() => import('/views/sections/product-carousel'));

// Common Endpoints
const getProduct = (productID) => {
	return productServices.getProduct(productID);
}

const getProductProperties = (productID) => {
	return productServices.getProductProperties(productID);
}

const getRecentProducts = (excludeBarcode) => {
	return siteServices.getLastVisitedProducts(excludeBarcode);
}

const getRelatedProducts = (productID) => {
	return productServices.getRelatedProducts(productID);
}

const getTopProducts = (productID) => {
	return siteServices.getProductCategoryTopProducts(productID);
}

const getNewProducts = (productID) => {
	return siteServices.getProductCategoryNewProducts(productID);
}
const getSchema = (productID) => {
	return siteServices.getSchema('product', productID);
}

// const getCategoryTopProducts = () => {
// 	return siteServices.getCategoryTopProducts(2);
// }

// Getters
const getProductTitle = (kind, serial) => {
	return kind.product_name + ((serial && serial.title && serial.title.length && serial.title !== '-') ? ` - ${serial.title}` : '')
}

const getProductLinkParams = (kind, serial) => {
	if(kind) {
		return {
			href: 'productDetail',
			params: {
				slug: kind.slug ?? kebabCase(kind.title),
				id: kind.id,
				...(serial?.title?.length ? {
					serialSlug: serial.slug ?? kebabCase(serial.title),
					serialID: serial.id,
				} : {})
			}
		}
	}
}

const parseHead = (kind, serial, product, secondaryProductSchema, getRoutePath) => {
	const mainImage = kind.images.find(i => i.video_embed === false);
	const title = kind.seo_title ? kind.seo_title : getProductTitle(kind, serial);

	const { href: productUrl, params: productParams } = getProductLinkParams(kind, serial);
	
	let mainProductSchema = {
		"@context": "https://schema.org/",
		"@type": "Product",
		"name": title,
		"image": kind.images.map(i => (`${kind.image_types.original}${i.url}`)),
		"description": kind.description,
		"mpn": kind.product_code,
		"sku": kind.barcode,
		"brand": {
			"@type": "Brand",
			"name": kind.brand,
		}
	};

	if(kind.product_status !== 0) {
		mainProductSchema.offers = {
			"@type": "Offer",
			"url": config.siteURL + getRoutePath(productUrl, productParams),
			"priceCurrency": "TRY",
			"price": kind.price.original.toString(),
			"itemCondition": "https://schema.org/NewCondition",
		}
	}
	else {
		mainProductSchema.offers = {
			"@type": "Offer",
			"url": config.siteURL + getRoutePath(productUrl, productParams),
			"availability": "https://schema.org/Discontinued",
			"priceCurrency": "TRY",
			"price": "0",
			"itemCondition": "https://schema.org/NewCondition",
		}
	}

	if(product.comment_average && product.comment_average !== 0) {
		mainProductSchema.aggregateRating =  {
			"@type": "AggregateRating",
			"ratingValue": product.comment_average,
			"reviewCount": product.comment_count,
			"bestRating": "5",
		};
	}

	if(product.featured_comment) {
		mainProductSchema.review = {
			"@type": "Review",
			"reviewRating": {
				"@type": "Rating",
				"ratingValue": product.featured_comment.score.toString(),
				"bestRating": "5"
			},
			"author": {
				"@type": "Person",
				"name": `${product.featured_comment.name} ${product.featured_comment.surname}`
			}
		}
	}

	let schemas = [JSON.stringify(mainProductSchema)];

	if(secondaryProductSchema && secondaryProductSchema.length > 0) {
		schemas = [
			...schemas,
			...secondaryProductSchema.map(schema => schema.description),
		]
	}

	return {
		type: 'product',
		title: title,
		description: kind.seo_description ? kind.seo_description : kind.description,
		image: mainImage ? (kind.image_types.large + mainImage.url) : false,
		breadcrumbs: [ ...product.category_breadcrumb, { name: kind.product_name }],
		schema: schemas,
		canonical: getRoutePath('productDetail', { id: product.kinds[0].id, slug: product.kinds[0].slug ? product.kinds[0].slug : 'urun' }),
	}
}

const getInitialKind = (product) => {
	if(product) {
		return product.kinds.length ? product.kinds.find(k => k.id === product.id) : product;
	}
	else {
		return false;
	}
}

const getInitialSerial = (kind, urlSerial = false) => {
	if(kind) {
		if(!(kind.serials && kind.serials.length > 0)) {
			return null;
		}
		else {

			let urlSerialResult = false;
			let kindSerialResult = false;
	
			for(const serial of kind.serials) {
				if(urlSerial && serial.id === urlSerial) {
					urlSerialResult = serial;
				}
				
				if(kind.serials.length === 1 && (kind.serials[0].title === '' || kind.serials[0].title === '-')) {
					kindSerialResult = serial;
				}
				else if(serial.id === kind.serial_id && serial.stock > 0) {
					kindSerialResult = serial;
				}
			}
	
			return (urlSerialResult ? urlSerialResult : (kindSerialResult ? kindSerialResult : false));
		}
	}
	else {
		return false;
	}
}

const processProduct = (product) => {
	const processGender = (type) => {
		if(!type || type.length < 1) {
			return false;
		}
		else if(type === 1) {
			return 'Erkek';
		}
		else if(type === 2) {
			return 'Kız';
		}
		else {
			return 'Unisex';
		}
	}

	const installmentCount = product.installment ? product.installment.installment_calculate : 1;

	return {
		...product,
		installment_price: (product.price.original / installmentCount).toFixed(2).replace('.', ','),
		installment_count: installmentCount,
		gender_name: processGender(product.gender_type),
		kinds: product.kinds.map(k => ({
			...k,
			installment_price: (k.price.original / installmentCount).toFixed(2).replace('.', ','),
			installment_count: installmentCount,
			gender_name: processGender(k.gender_type),
		}))
	}
}

// Main Component
const ProductDetail = ({ match, pageProps }) => {
	const _mounted = useRef(false);

	const matchParams = useParams('productDetail');
	const { sendGtagEvent, sendAnalyticsEvent } = useAnalytics();
	const { initialized: authInitialized, userData } = useAuth();
	const { setHead } = useHead();
	const { mobile } = useBreakpoints();
	const { redirect, getRoutePath } = useNavigator();
	const { sysParameters, isApplication, arviaWidgetReady, setNotFound, setError } = useGlobalState();
	// const { indexedProducts: basketContent } = useBasket();
	// const installmentCount = useRef(props.installmentCount);

	const { paramProductID, paramSerialID } = useMemo(() => {
		return {
			paramProductID: matchParams.id ? parseInt(matchParams.id) : null,
			paramSerialID: matchParams.serialID ? parseInt(matchParams.serialID) : null,
		}
	}, [matchParams])

	const [product, setProduct] = useState(pageProps?.product ?? false);
	const [recentProducts, setRecentProducts] = useState(false);
	const [relatedProducts, setRelatedProducts] = useState(false);
	const [topProducts, setTopProducts] = useState(false);
	const [newProducts, setNewProducts] = useState(false);
	const [campaignData, setCampaignData] = useState(false);
	// const [categoryTopProducts, setCategoryTopProducts] = useState(false);
	// const [selectedSerial, setSelectedSerial] = useState(pageProps?.selectedSerial ?? false);
	const [productSchema, setProductSchema] = useState(pageProps?.productSchema ?? null);

	const fetchingProductID = useRef(false);

	const relatedSliderData = useMemo(() => {
		let sliders = [];

		if(relatedProducts === false || (relatedProducts && relatedProducts.products.length > 0)) {
			sliders.push({
				title: relatedProducts.title && relatedProducts.title.length > 0 ? relatedProducts.title : 'Benzer Ürünler',
				icon: 'history',
				key: 'related',
				products: relatedProducts ? relatedProducts.products : false,
			})
		}
		if(recentProducts === false || (recentProducts && recentProducts.products.length > 0)) {
			sliders.push({
				title: 'Son Gezilen Ürünler',
				icon: 'history',
				key: 'recent',
				products: recentProducts ? recentProducts.products : false,
			})
		}

		return sliders.length > 0 ? sliders : false;
	}, [relatedProducts, recentProducts])

	// Önceden disabled
	// useEffect(() => {
	// 	if (!categoryTopProducts) {
	// 		getCategoryTopProducts().then((payload) => {
	// 			if(_mounted.current) {
	// 				setCategoryTopProducts(payload);
	// 			}
	// 		});
	// 	}
	// }, [categoryTopProducts])

	const isSupportProduct = useMemo(() => {
		return !!(product?.category_breadcrumb ?? []).find(c => c?.params?.id === 446)
	}, [product])

	const selectedKind = useMemo(() => {
		return (product?.kinds ?? []).find(kind => kind.id === paramProductID);
	}, [product, paramProductID]);

	const selectedSerial = useMemo(() => {
		if(selectedKind) {
			return getInitialSerial(selectedKind, paramSerialID);
		}

		return null;
	}, [selectedKind, paramSerialID])

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, [])

	// Stoksuz veya geçersiz serial yönlendirme
	useEffect(() => {
		if(selectedKind && (selectedSerial && selectedSerial.stock === 0 || (!selectedSerial && selectedKind.serials.length)) && !paramSerialID) {
			const newSerial = selectedKind.serials.find(serial => serial.stock > 0) ?? (selectedSerial ?? selectedKind.serials[0]);

			if(newSerial) {
				const { href, params } = getProductLinkParams(selectedKind, newSerial);
				redirect(href, params);
			}
		}
	}, [selectedSerial, selectedKind, paramSerialID])

	// Get Product Data
	useEffect(() => {		
		if(paramProductID && fetchingProductID.current !== paramProductID) {
			// Reset product data to fetch new
			if (product && !selectedKind) {
				setProduct(null);
			}
			
			if(!selectedKind) {
				fetchingProductID.current = paramProductID;

				getProduct(paramProductID).then((payload) => {
					if(_mounted.current) {
						const processedPayload = processProduct(payload);
						setProduct(processedPayload);
					}
				}).catch((status) => {
					if(_mounted.current) {
						fetchingProductID.current = false;
						if(status === 404) {
							setNotFound();
						}
						else {
							setError();
						}
					}
				})
			}
		}
		// Reset fetching id
		else if(paramProductID && fetchingProductID.current === paramProductID && selectedKind) {
			fetchingProductID.current = paramProductID;
		}
	}, [product, selectedKind, paramProductID]);

	// Get Other Data
	useEffect(() => {
		if (selectedKind && (!relatedProducts || relatedProducts.id !== selectedKind.id)) {
			setRelatedProducts({
				id: selectedKind.id,
				title: false,
				products: false,
			});
			getRelatedProducts(selectedKind.id).then((payload) => {
				if(_mounted.current) {
					setRelatedProducts({ id: selectedKind.id, ...payload });
				}
			}).catch(() => {
				setRelatedProducts({ products: []})
			})
		}
	}, [selectedKind, relatedProducts]);
	
	useEffect(() => {
		if (product && (!recentProducts || recentProducts.barcode !== product.barcode)) {
			setRecentProducts({
				barcode: product.barcode,
				products: false,
			});
			getRecentProducts(product.barcode).then((payload) => {
				if(_mounted.current) {
					setRecentProducts({ barcode: product.barcode, products: payload });
				}
			}).catch(() => {
				setRecentProducts({ products: []})
			})
		}
	}, [recentProducts, product]);

	useEffect(() => {
		if (paramProductID && (!topProducts || paramProductID.toString() !== topProducts.productID.toString())) {
			getTopProducts(paramProductID).then((payload) => {
				if(_mounted.current) {
					setTopProducts(payload);
				}
			}).catch(() => {
				setTopProducts({ products: []})
			})
		}
	}, [topProducts, paramProductID]);

	useEffect(() => {
		if (paramProductID && (!newProducts || paramProductID.toString() !== newProducts.productID.toString())) {
			getNewProducts(paramProductID).then((payload) => {
				if(_mounted.current) {
					setNewProducts(payload);
				}
			}).catch(() => {
				setNewProducts({ products: []})
			})
		}
	}, [newProducts, paramProductID]);

	// Analytics
	useEffect(() => {
		if(product && selectedKind && selectedSerial) {
			sendGtagEvent({
				products: [parseAnalyticsProduct({
					...selectedKind,
					serial_title: selectedSerial?.title || false,
				}, {
					category: (product.category_breadcrumb && product.category_breadcrumb.length > 0) ? product.category_breadcrumb[product.category_breadcrumb.length - 1].name : false,
				})],
			});
		}
	}, [selectedKind, selectedSerial, product])

	useEffect(() => {
		if(product && selectedKind && selectedSerial && authInitialized) {
			sendAnalyticsEvent({
				event: 'view_product_detail',
				user: userData,
				products: [parseAnalyticsProduct({
					category_breadcrumb: product.category_breadcrumb,
					...selectedKind,
					serial_title: selectedSerial?.title || false,
				}, {
					category: (product.category_breadcrumb && product.category_breadcrumb.length > 0) ? product.category_breadcrumb[product.category_breadcrumb.length - 1].name : false,
				})],
			})
		}
	}, [selectedKind, selectedSerial, product, sendAnalyticsEvent, authInitialized, userData])

	// Campaign data
	useEffect(() => {
		if(!selectedSerial) {
			setCampaignData(false);
		}
		else if(!campaignData || campaignData.serial !== selectedSerial.id) {
			productServices.getProductCampaigns(selectedSerial.id).then((campaigns) => {
				if(_mounted.current) {
					setCampaignData({
						serial: selectedSerial.id,
						campaigns: campaigns,
					})
				}
			}).catch(() => {
				if(_mounted.current) {
					setCampaignData({serial: selectedSerial.id, campaigns: false});
				}
			})
		}
	}, [campaignData, selectedSerial])

	// Arvia Widget
	useEffect(() => {
		if(arviaWidgetReady && window?.arviaWidgetSetTag && window.arviaWidgetVisibleWidget) {
			if(product?.video_chat === 1 && product.video_catagory) {
				window.arviaWidgetSetTag(product.video_catagory);
				window.arviaWidgetVisibleWidget(true);
			}
			else {
				window.arviaWidgetVisibleWidget(false);
			}
		}
	}, [product, arviaWidgetReady])

	// Schema Data
	useEffect(() => {
		if(paramProductID) {
			setProductSchema(null);
			getSchema(paramProductID).then((payload) => {
				setProductSchema(payload);
			})
		}
	}, [paramProductID])

	// Head
	useEffect(() => {
		if(selectedSerial && product && selectedKind && productSchema !== null) {
			setHead(parseHead(selectedKind, selectedSerial, product, productSchema, getRoutePath));

		}
	}, [selectedKind, selectedSerial, product, productSchema])
	

	// Slug Fix
	useEffect(() => {
		if(selectedKind) {
			const { href, params: newParams } = getProductLinkParams(selectedKind, selectedSerial);

			let overrideParams = {};

			if(newParams.slug !== matchParams.slug) {
				overrideParams = {
					...overrideParams,
					slug: newParams.slug,
					id: newParams.id,
				}
			}

			if(matchParams.serialSlug && newParams.serialSlug !== matchParams.serialSlug) {
				overrideParams = {
					...overrideParams,
					serialSlug: newParams.serialSlug,
					serialID: newParams.serialID,
				}
			}

			if(Object.keys(overrideParams).length > 0) {
				redirect(href, { ...matchParams, ...overrideParams }, { replace: true });
			}
		}
	}, [selectedKind, selectedSerial, matchParams, paramSerialID])

	const basketProduct = useBasketProduct(selectedSerial?.id);

	return (
		<>
			<Breadcrumbs
				data={product && selectedKind ? [ ...product.category_breadcrumb, { name: selectedKind.product_name }] : false} />

			<ProductInfo
				mobile={mobile}
				isApplication={isApplication}
				match={match}
				product={product}
				basketProduct={basketProduct}
				selectedKind={selectedKind}
				selectedSerial={selectedSerial}
				userData={userData}
				campaigns={campaignData?.campaigns}
				sysParameters={sysParameters}
				isSupportProduct={isSupportProduct} />

			<ProductCarousel
				tabs
				className="detail-popularproducts"
				data={[
					{
						title: 'En Çok Satan Ürünler',
						icon: 'trend-up',
						key: 'top',
						products: topProducts ? topProducts.products : false,
					},
					{
						title: 'Yeni Ürünler',
						icon: 'new',
						key: 'new',
						products: newProducts ? newProducts.products : false,
					}
				]} />

			{/* <CategoryTopProducts
				className="detail-category-top-products"
				data={categoryTopProducts} /> */}
				
			{relatedSliderData &&
				<ProductCarousel
					tabs
					className="detail-recentproducts"
					data={relatedSliderData} />
			}
		</>
	)
}

const ProductInfo = ({
	match,
	product, basketProduct, selectedKind, selectedSerial, campaigns,
	userData,
	isSupportProduct,
}) => {
	const _mounted = useRef();
	const bottomContainer = useRef();
	const { openModal } = useModals();
	const { mobile } = useBreakpoints();
	const { isApplication, sysParameters } = useGlobalState();
	const { increaseQuantity, busy } = useBasketProductControls(selectedKind, selectedSerial);
	const {
		favoriteBusy,
		alarmsBusy,
		alarmProducts,
		favoriteProducts,
		toggleAlarm,
		toggleFavorite: toggleFavoriteRaw,
	} = useWishlist();

	const [activeImage, setActiveImage] = useState((selectedKind && selectedKind.images && selectedKind.images.length) ? selectedKind.images[0] : false);
	const [comments, setComments] = useState(false);
	const [activeBottomTab, setActiveBottomTab] = useState(null);

	const [mainSwiper, setMainSwiper] = useState(null);
	// const [thumbsSwiper, setThumbsSwiper] = useState(null);

	// const internetExclusive = useMemo(() => {
	// 	const availableOnStore = [0, 1, 2].includes(selectedKind.fix_status);
	// 	const availableOnline = [2, 3, 4].includes(selectedKind.fix_status);

	// 	return !availableOnStore && availableOnline;
	// }, [selectedKind]);

	// const productAddable = selectedSerial || !selectedSerial && product.serials.length === 1 && product.serials[0].title;

	const shareProduct = async () => {
		const link = config.siteURL+match.url;
		try {
			await navigator.share({
				title: selectedKind.product_name + (selectedSerial ? ` - ${selectedSerial.title}`:''),
				url: match.url
			});
		}
		catch (e) {
			if(e.name !== 'AbortError') {
				openModal('alert', {
					title: 'Ürünü paylaş',
					message: `<span class="link smalltext">${link}</span> <br />Adresini kopyalayarak ürünü paylaşabilirsiniz.`,
				})
			}
		}
	}

	const toggleFavorite = () => {
		if(userData) {
			if(selectedSerial) {
				toggleFavoriteRaw({
					...selectedKind,
					serial_id: selectedSerial.id,
					serial_title: selectedSerial.title,
				});
			}
			else {
				openModal('alert', { message: 'Favorilerinize eklemek istediğiniz bedeni seçmelisiniz.' })
			}
		}
		else {
			openModal('login-message', { message: 'Bu ürünü favorilerinize eklemek için üye girişi yapmalısınız.' })
		}
	}

	const togglePriceAlarm = () => {
		if(userData) {
			if(!alarmsBusy) {
				if(selectedSerial) {
					toggleAlarm({
						...selectedKind,
						serial_id: selectedSerial.id,
						serial_title: selectedSerial.title,
					}, 'price');
				}
				else {
					openModal('alert', { message: 'Fiyat listenize eklemek istediğiniz bedeni seçmelisiniz.' })
				}
			}
		}
		else {
			openModal('login-message', { message: 'Bu ürünü fiyat listenize eklemek için üye girişi yapmalısınız.' })
		}
	}

	const toggleStockAlarm = () => {
		if(userData) {
			if(!alarmsBusy) {
				if(selectedSerial) {
					toggleAlarm({
						...selectedKind,
						serial_id: selectedSerial.id,
						serial_title: selectedSerial.title,
					}, 'stock');
				}
				else {
					openModal('alert', { message: 'Stok listenize eklemek istediğiniz bedeni seçmelisiniz.' })
				}
			}
		}
		else {
			openModal('login-message', { message: 'Bu ürünü stok listenize eklemek için üye girişi yapmalısınız.' })
		}
	}

	const scrollToComments = () => {
		setActiveBottomTab('comments');
		
		setTimeout(() => {
			scrollToElement(mobile ? document.getElementById('product-detail-comments') : bottomContainer.current, -2);
		}, mobile ? 250 : 10);
	}

	const campaignLabel = useMemo(() => {
		if(selectedKind?.campaign?.enddate && selectedKind.campaign.enddate.length > 0) {
			const today = new Date();
			const endDate = new Date(selectedKind.campaign.enddate.replace(/-/g, "/")); // '2022-02-27 05:00:00'
			const dayDiff = endDate ? differenceInDays(endDate, today) : false;
			const hourDiff = endDate ? differenceInHours(endDate, today) : false;

			return {
				...selectedKind.campaign,
				infoText: endDate ? (dayDiff > 4 ? selectedKind.campaign.description : (`son ${dayDiff === 0 ? hourDiff + ' saat' : dayDiff + ' gün'}`)) : false,
			}
		}
		else {
			return false;
		}
	}, [selectedKind])

	const leftStock = useMemo(() => {
		return (selectedSerial && selectedSerial.stock < 11 && selectedSerial.stock > 0) ? selectedSerial.stock : false;
	}, [selectedSerial])

	const addToBasket = () => {
		if(selectedSerial) {
			increaseQuantity()
		}
		else {
			openModal('alert', {
				message: 'Ürünü sepete eklemek için bir beden seçmelisiniz.',
			})
		}
	}

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, []);

	useEffect(() => {
		setActiveImage((selectedKind && selectedKind.images && selectedKind.images.length) ? selectedKind.images[0] : false);
	}, [selectedKind])

	return (
		<main className="section detail-info">
			<div className="wrapper info-wrap">
				{(product && selectedKind) ?
					<>
						<div className="info-left">
							{(isApplication && mobile) &&
								<div className="left-controls">
									<Btn
										onClick={shareProduct}
										type="button"
										icon="share"
										className="controls-btn rounded small" />
									{selectedKind.product_status !== 0 &&
										<>
											<Btn
												disabled={favoriteBusy}
												onClick={toggleFavorite}
												type="button"
												data-gtm-id={(selectedSerial && favoriteProducts[selectedSerial.id]) ? 'removeFromFavorites' : 'addToFavorites'}
												icon={(selectedSerial && favoriteProducts[selectedSerial.id]) ? 'heart-full' : 'heart-empty'}
												className={`controls-btn rounded small ${(selectedSerial && favoriteProducts[selectedSerial.id]) ? ' primary' : ''}`} />

											<Btn
												disabled={alarmsBusy}
												onClick={togglePriceAlarm}
												type="button"
												icon="price-drop"
												data-gtm-id={(selectedSerial && alarmProducts.price[selectedSerial.id]) ? 'removeFromPriceList' : 'addToPriceList'}
												className={`controls-btn rounded small ${(selectedSerial && alarmProducts.price[selectedSerial.id]) ? ' primary' : ''}`} />

											<Btn
												onClick={() => {
													openModal('product-comparison', {
														product: { ...product, ...selectedKind },
													})
												}}
												data-gtm-id="addToCompareList"
												type="button"
												icon="comparison"
												className="controls-btn rounded small" />
										</>
									}
								</div>
							}
							<div className="left-gallery">	
								{selectedKind.images.length > 0 ?
									<>
										<Slider
											key={`detail-main-${selectedKind ? selectedKind.id : 'empty'}-${mobile ? 'mobile' : 'web'}`}
											params={{
												onSwiper: setMainSwiper,
												navigation: (!mobile && selectedKind.images.length > 1),
												onSlideChange: (e) => {
													setActiveImage(selectedKind.images[e.realIndex]);
												}
											}}
											className="gallery-mainslider">
											{selectedKind.images.map((image, nth) => (
												<button
													className={`mainslider-imagewrap${(image.video_embed ? ' is-video' : '')}`}
													onClick={(e) => {
														e.stopPropagation();
														openModal('product-gallery', {
															product: selectedKind,
															activeImage: activeImage,
														})
													}}
													key={nth}>
													{image.video_embed ?
														<Img
															className="imagewrap-image video-thumb"
															src={`https://img.youtube.com/vi/${image.video_embed_href.split('=')[1]}/hqdefault.jpg`} />
														:
														<Img
															loading={nth === 0 ? 'eager' : undefined}
															className="imagewrap-image"
															alt={selectedKind.product_name}
															src={`${selectedKind.image_types.thumbnail}${image.url}`} />
													}
												</button>
											))}
										</Slider>

										{(
											(isApplication && mobile) &&
											((selectedKind.product_status !== 0 && (selectedKind.slogan && selectedKind.slogan.length > 0)) || leftStock || campaignLabel)
										) &&
											<div className="gallery-bottomlabels">
												{campaignLabel &&
													<span className="bottomlabels-campaign label pink-bg">
														{campaignLabel.name}
														{campaignLabel.infoText &&
															<span className="campaign-infotext">
																{campaignLabel.infoText}
															</span>
														}
													</span>
												}
												{selectedKind.slogan &&
													<span className="label pink-bg">{selectedKind.slogan}</span>
												}
												{leftStock &&
													<span className="label pink">Son {leftStock} Ürün</span>
												}
											</div>
										}

										<div className="gallery-thumbswrap">
											<Slider
												key={`detail-thumbs-${mobile ? 'mobile' : 'web'}`}
												params={{
													// onSwiper: setThumbsSwiper,
													direction: mobile ? 'horizontal' : 'vertical',
													slidesPerView: mobile ? 6 : 5,
													navigation: !mobile,
													preventClicksPropagation: true,
													preventInteractionOnTransition: true,
													resistanceRatio: 0.99,
													resistance: true,
												}}
												resistanceRatio={1}
												className="gallery-thumbs">
												{selectedKind.images.map((image, nth) => (
													<button
														className={'thumbs-thumb' + (activeImage && activeImage.id === image.id ? ' active' : '')}
														onClick={(e) => {
															e.stopPropagation();
															setActiveImage(image);
															if(mainSwiper) {
																mainSwiper.slideTo(nth + 1);
															}
														}}
														key={nth}>
														<div className="thumb-imagewrap">
															<Img
																cover
																className={`thumb-image${image.video_embed ? ' video' : ''}`}
																alt={image.title ? image.title : selectedKind.title}
																title={image.title ? image.title : selectedKind.title}
																src={image.video_embed ? `https://img.youtube.com/vi/${image.video_embed_href.split('=')[1]}/default.jpg` : selectedKind.image_types.thumbnail + image.url} />
														</div>
													</button>
												))}
											</Slider>
										</div>
									</>
									:
									<div className="mainslider-imagewrap empty">
										<Img
											className="imagewrap-image"
											src={false} />
									</div>
								}
							</div>
							{!mobile && product.featured_comment &&
								<ProductFeaturedComment
									mobile={mobile}
									onScrollToComments={scrollToComments}
									className="left-featuredcomment"
									product={product} />
							}
						</div>

						<InputForm className="info-right portlet" onSubmit={addToBasket}>
							{(isApplication && mobile) &&
								<div className="info-appcontrols">
									{selectedKind.product_status !== 0 ?
										<>
											<div className="appcontrols-left">
												{((selectedKind.slogan && selectedKind.slogan.length > 0) || leftStock) &&
													<div className="left-labels">
														{selectedKind.slogan &&
															<span className="labels-label">{selectedKind.slogan}</span>
														}
														{leftStock &&
															<span className="labels-label">Son {leftStock} Ürün</span>
														}
													</div>
												}
												<Pricebox className="appcontrols-pricebox" product={selectedKind} />
											</div>
											<div className="appcontrols-btns">
												<div className="btns-group">
												{(selectedSerial && selectedSerial.stock === 0) ?
													<>
														<Btn
															disabled
															className="btns-btn small primary-dark">
															Stokta Yok
														</Btn><br />
														<button
															disabled={alarmsBusy}
															loading={alarmsBusy}
															onClick={toggleStockAlarm}
															className="link btns-stockbtn">
															{alarmProducts && alarmProducts.stock[selectedSerial.id] ?
																"Listeden Çıkar"
																:
																"Gelince Haber Ver"
															}
														</button>
													</>
													:
													<Btn
														disabled={(basketProduct && basketProduct.updating)}
														loading={basketProduct && basketProduct.updating}
														data-gtm-id="addToBasket"
														type="submit"
														className="btns-btn primary">
														Sepete Ekle
													</Btn>	
												}
												</div>
											</div>
										</>
										:
										<>
											Bu ürün şu an satılmamaktadır.
										</>
									}
								</div>
							}
							{isSupportProduct &&
								<div className="right-supportnotification">
									Bu ürün doğrudan afet yardım kurumlarına iletilecek şekilde satın alınabilir.
								</div>
							}
							<h1 className="right-title">{selectedKind.product_name}</h1>
							{(selectedKind.brand && selectedKind.brand_id) &&
								<Link
									href="brand"
									params={{ id: selectedKind.brand_id, slug: kebabCase(selectedKind.brand) }}
									className="right-brandlink">
										Tüm {selectedKind.brand} Ürünleri
									</Link>
							}

							<div className="right-badgebar">
								{selectedKind.product_status !== 0 &&
										<div className="badgebar-labels">
											{!!selectedKind?.is_internet_exclusive &&
												<span className="label blue-bg uppercase">İnternete Özel</span>
											}
											{!!selectedKind?.is_outlet &&
												<span className="label pink-bg uppercase">Outlet</span>
											}
											{selectedKind.bestseller === true ?
												<span className="label orange-bg uppercase">En Çok Satan</span>
												:
												<>
													{selectedKind.new_product === true &&
														<span className="label orange-light-bg uppercase">Yeni</span>
													}
												</>
											}
											{selectedKind.exclusive === true &&
												<span className="label pink-bg">Sadece Babymall’da</span>
											}
											{(selectedKind.tags && selectedKind.tags.length > 0) &&
												<>
													{selectedKind.tags.map((tag, nth) => (
														<span className="label purple" key={nth}>{tag.name}</span>
													))}
												</>
											}
										</div>
									}

								<div className="badgebar-rating">
									<RatingBox className="rating-stars" rating={product.comment_average} />
									<span>{product.comment_average !== 0 && product.comment_average_string} ({product.comment_count} yorum)</span>
								</div>
							</div>

							{selectedKind.product_status !== 0 &&
								<>
									{!(isApplication && mobile) &&
										<Pricebox
											big
											className="right-pricebox"
											product={selectedKind} />
									}

									{(!(isApplication && mobile) && ((selectedKind.slogan && selectedKind.slogan.length > 0) || leftStock || campaignLabel)) &&
										<div className="right-bottomlabels">
											{campaignLabel &&
												<span className="bottomlabels-campaign label pink-bg">
													{campaignLabel.name}
													{campaignLabel.infoText &&
														<span className="campaign-infotext">
															{campaignLabel.infoText}
														</span>
													}
												</span>
											}
											{selectedKind.slogan &&
												<span className="label pink-bg">{selectedKind.slogan}</span>
											}
											{leftStock &&
												<span className="label pink">Son {leftStock} Ürün</span>
											}

										</div>
									}

									{(selectedKind.installment_status === 1 && selectedKind.installment_count > 1) &&
										<div className="right-installmentinfo">
											<div className="installmentinfo-wrap">
												{/*<strong>{selectedKind.installment_price} TL x {selectedKind.installment_count} ay</strong>'a varan*/} <button type="button" onClick={() => { openModal('product-installment-options', { product: selectedKind }) }}>Taksit Seçenekleri</button>
											</div>
										</div>
									}

									<hr className="right-seperator"></hr>
								</>
							}

							
							{(selectedKind.serials && (selectedKind.serials.length > 1 || (selectedKind.serials[0].title !== '-' && selectedKind.serials[0].title !== ''))) &&
								<>
									<div className="right-opts">
										<div className="opts-head">
											<strong className="opts-title">Beden Seçenekleri</strong>
											<Btn
												onClick={() => {
													openModal('size-chart');
												}}
												className="outline smaller"
												icon="measure">
												Beden Tablosu
											</Btn>
										</div>

										<ul className="opts-list">
											{selectedKind.displaySerials.map(serial => {
												const isActive = serial.id === selectedSerial?.id;
												const Tag = isActive ? 'span' : Link;
												const props = isActive ? {
													className: 'opt-btn active',
												} : {
													className: 'opt-btn',
													noScroll: true,
													...getProductLinkParams(selectedKind, serial),
												};

												return (
													<li
														className="list-opt"
														key={serial.id}>
														<Tag {...props}>
															{serial.title}
															{serial.stock === 0 &&
																<p className="opt-subtitle">Tükendi</p>
															}
														</Tag>
													</li>
												)
											})}
										</ul>
									</div>

									<hr className="right-seperator"></hr>
								</>
							}

							{product.displayKinds && product.displayKinds.length > 1 &&
								<>
									<div className="right-opts">
										<div className="opts-head">
											<strong className="opts-title">Ürün Seçenekleri</strong>
										</div>
										<ul className="opts-list">
											{product.displayKinds.map((kind) => {
												const isActive = kind.id === selectedKind.id;

												const Tag = isActive ? 'span' : Link;
												const props = isActive ? {
													className: 'opt-btn kind active',
												} : {
													className: 'opt-btn kind',
													noScroll: true,
													...getProductLinkParams(kind),
												};

												return (
													<li
														key={kind.id}
														className="list-opt">
														<Tag {...props}>
															<Img
																className="opt-image"
																src={kind.images.length ? kind.image_types.thumbnail + kind.images[0].url : false} />
															<div className="opt-textwrap">
																<p className="textwrap-title">{kind.kind_name}</p>
																{kind.product_status !== 0 ?
																	<p className="textwrap-price">{kind.price.original_str} TL</p>
																	:
																	<p className="textwrap-price inactive">Satılmamaktadır</p>
																}
															</div>
														</Tag>
													</li>
												)
											})}
										</ul>
									</div>
									<hr className="right-seperator"></hr>
								</>
							}

							{selectedKind.product_status !== 0 ?
								<>

									{!(isApplication && mobile) &&
										<>
											{(selectedSerial && selectedSerial.stock === 0) ?
												<Btn
													disabled={alarmsBusy}
													loading={alarmsBusy}
													onClick={toggleStockAlarm}
													className="right-addbtn big block primary-dark">
													{alarmProducts && alarmProducts.stock[selectedSerial.id] ?
														"Stok Bekleme Listemden Çıkar"
														:
														"Stok Gelince Haber Ver"
													}
												</Btn>		
												:
												<Btn
													disabled={(busy)}
													loading={busy}
													type="submit"
													data-gtm-id="addToBasket"
													className="right-addbtn big block primary">
													Sepete Ekle
												</Btn>	
											}
										</>
									}

									{(selectedKind.given_cargo_day && selectedSerial.stock !== 0) &&
										<div className="right-cargodate">
											En geç kargoya teslim edilme tarihi <strong>{formatDate(selectedKind.given_cargo_day, 'd MMMM')}</strong>
										</div>
									}

									<ul className="right-features">
										{sysParameters?.['free_cargo_price_limit']?.description &&
											<>
												{(selectedKind.price.original >= parseInt(sysParameters['free_cargo_price_limit'].description)) ?
													<li className="features-feature orange">
														<span className="feature-title">Ücretsiz <br />Kargo</span>
														<Icon name="feature-cargo" />
													</li>
													:
													<li className="features-feature orange">
														<span className="feature-title">
															{sysParameters['free_cargo_price_limit'].description} TL Üzeri <br />Ücretsiz Kargo
														</span>
														<Icon name="feature-cargo" />
													</li>
												}
											</>
										}
										{/*availableOnStore &&
											<li className="features-feature">
												<span className="feature-title">Mağazadan Teslim <br />Alınabilir</span>
												<Icon name="feature-change" />
											</li>
										*/}
										{selectedKind?.cargo_day === 0 &&
											<li className="features-feature primary">
												<span className="feature-title">Aynı gün <br />kargo</span>
												<Icon name="feature-cargo" />
											</li>
										}
									</ul>

									<Btn
										onClick={() => {
											openModal('store-stock-lookup', {
												product: selectedKind,
												initialSerial: selectedSerial,
											});
										}}
										className="right-storestockbtn small outline nude blue-medium"
										icon="store">
										Hangi Mağazalarda Var?
									</Btn>

									<hr className="right-seperator"></hr>
								</>
								:
								<EmptyMessage className="right-emptymessage" lined>
									Bu ürün şu an satılmamaktadır.
								</EmptyMessage>
							}

							{!(isApplication && mobile) &&
								<>
									{selectedKind.product_status !== 0 &&
										<div className="right-controls">
											<button
												disabled={favoriteBusy}
												onClick={toggleFavorite}
												data-gtm-id={(selectedSerial && favoriteProducts[selectedSerial.id]) ? 'removeFromFavorites' : 'addToFavorites'}
												type="button"
												className="controls-btn">
												{(selectedSerial && favoriteProducts[selectedSerial.id]) ?
													<>
														<Icon name="heart-full" /> Favorilerimden <br className="only-mobile" />Çıkar
													</>
													:
													<>
														<Icon name="heart-empty" /> Favorilerime <br className="only-mobile" />Ekle
													</>	
												}
											</button>
											<button
												disabled={alarmsBusy}
												onClick={togglePriceAlarm}
												data-gtm-id={(selectedSerial && alarmProducts.price[selectedSerial.id]) ? 'removeFromPriceList' : 'addToPriceList'}
												type="button"
												className="controls-btn">
												{(selectedSerial && alarmProducts.price[selectedSerial.id]) ?
													<>
														<Icon name="price-drop" /> Fiyat Bekleme Listemden Çıkar
													</>
													:
													<>
														<Icon name="price-drop" /> Fiyat Düşünce Haber Ver
													</>
												}
											</button>
											<button
												onClick={() => {
													openModal('product-comparison', {
														product: { ...product, ...selectedKind },
													})
												}}
												data-gtm-id="addToCompareList"
												type="button"
												className="controls-btn">
												<Icon name="comparison" /> Karşılaştır
											</button>
										</div>
									}
									<div className="right-share">
										<strong>Ürünü Paylaş</strong>
										<>
											<SocialShareBtn
												initialURL={match.url}
												platform="facebook">
												<Icon name="facebook" />
											</SocialShareBtn>
											<SocialShareBtn
												initialURL={match.url}
												platform="x"
												title={selectedKind.product_name + (selectedSerial ? ` - ${selectedSerial.title}`:'')}>
												<Icon name="x" />
											</SocialShareBtn>
											<SocialShareBtn
												initialURL={match.url}
												platform="whatsapp"
												title={selectedKind.product_name + (selectedSerial ? ` - ${selectedSerial.title}`:'')}>
												<Icon name="whatsapp" />
											</SocialShareBtn>
										</>
									</div>
								</>
							}

						</InputForm>

						{mobile && product.featured_comment && 
							<ProductFeaturedComment
								mobile={mobile}
								onScrollToComments={scrollToComments}
								className="info-featuredcomment"
								product={product} />
						}

						{!mobile &&
							<SiteFeatures className="info-sitefeatures" />
						}
						
						{!mobile ?
							<div className="info-bottom portlet" ref={bottomContainer}>
								<Tabs
									activeTab={activeBottomTab}
									onChange={setActiveBottomTab}
									className="bottom-tabs">
									<div
										index="description"
										label="Ürün Özellikleri">
										<ProductDescription
											product={selectedKind} />
									</div>
									{(campaigns && campaigns.length > 0) &&
										<div
											index="promotions"
											label="Kampanyalar">
											<ProductCampaigns campaigns={campaigns} />
										</div>
									}
									<div
										index="comments"
										label="Ürün Yorumları">
										<ProductComments
											onCommentsChange={setComments}
											comments={comments}
											product={product}
											userData={userData} />
									</div>
									<div
										index="installments"
										label="Taksit Seçenekleri">
										<ProductInstallments product={selectedKind} />
									</div>
									{sysParameters?.product_detail_return_text &&
										<div
											index="return"
											label="Garanti ve İade Şartları">
											<ProductReturnTerms data={sysParameters.product_detail_return_text} />
										</div>
									}
								</Tabs>
							</div>
							:
							<ul className="info-bottom accordion">
								<li>
									<Collapser
										onOpen={() => { setActiveBottomTab('description') }}
										open={activeBottomTab === 'description'}
										title="Ürün Özellikleri">
										<ProductDescription
											product={selectedKind} />
									</Collapser>
								</li>
								{(campaigns && campaigns.length > 0) &&
									<li>
										<Collapser
											onOpen={() => { setActiveBottomTab('promotions') }}
											open={activeBottomTab === 'promotions'}
											title="Kampanyalar">
											<ProductCampaigns campaigns={campaigns} />
										</Collapser>
									</li>
								}
								<li>
									<Collapser
										onOpen={() => { setActiveBottomTab('comments') }}
										open={activeBottomTab === 'comments'}
										id="product-detail-comments"
										title="Ürün Yorumları">
										<ProductComments
											onCommentsChange={setComments}
											comments={comments}
											product={product}
											userData={userData} />
									</Collapser>
								</li>
								{sysParameters?.product_detail_return_text &&
									<li>
										<Collapser
											onOpen={() => { setActiveBottomTab('return') }}
											open={activeBottomTab === 'return'}
											title="Garanti ve İade Şartları">
											<ProductReturnTerms data={sysParameters.product_detail_return_text} />
										</Collapser>
									</li>
								}
								<li>
									<Collapser
										onOpen={() => { setActiveBottomTab('installments') }}
										open={activeBottomTab === 'installments'}
										title="Taksit Seçenekleri">
										<ProductInstallments product={selectedKind} />
									</Collapser>
								</li>
							</ul>
						}

						{(isApplication && mobile && (product.category_breadcrumb.length > 1 || (selectedKind.brand && selectedKind.brand_id))) &&
							<div className="info-suggestions">
								{product.category_breadcrumb.map((crumb, nth) => {
									if(nth > 0) {
										return (
											<Link
												key={nth}
												className="suggestion-link"
												href={crumb.href}
												params={crumb.params}>
												<span>{crumb.name} Kategorisi</span>
											</Link>
										)
									}
									else { return false; }
								})}

								{(selectedKind.brand && selectedKind.brand_id) &&
									<Link
										href="brand"
										params={{ id: selectedKind.brand_id, slug: kebabCase(selectedKind.brand) }}
										className="suggestion-link">
										Tüm {selectedKind.brand} Ürünleri
									</Link>
								}
							</div>
						}
					</>
					:
					<>
						<div className="info-left">
							<div className="left-gallery">
								<div className="gallery-mainslider">
									<Placeholder className="mainslider-imagewrap" />
								</div>

								<div className="gallery-thumbswrap">
									<div className="gallery-thumbs placeholder">
										<div className="swiper-slide placeholder">
											<div className="thumbs-thumb">
												<Placeholder className="thumb-imagewrap" />
											</div>
										</div>
										<div className="swiper-slide placeholder">
											<div className="thumbs-thumb">
												<Placeholder className="thumb-imagewrap" />
											</div>
										</div>
										<div className="swiper-slide placeholder">
											<div className="thumbs-thumb">
												<Placeholder className="thumb-imagewrap" />
											</div>
										</div>
									</div>
								</div>
							</div>

							<div className="left-topcomment">
								<div className="topcomment-head">
									<div className="head-left">
										<Placeholder className="topcomment-rating" />
										<Placeholder className="topcomment-datetime" />
									</div>
									<Placeholder className="topcomment-author" />
								</div>

								<Placeholder className="topcomment-text" />

								<Placeholder className="topcomment-cta" />
							</div>
						</div>
						<div className="info-right portlet">
							<Placeholder className="right-title" />
							<Placeholder className="right-brandlink" />

							<div className="right-badgebar">
								<div className="badgebar-labels">
									<Placeholder />
									<Placeholder />
								</div>

								<div className="badgebar-rating">
									<Placeholder className="rating-stars" />
									<Placeholder className="rating-text" />
								</div>
							</div>

							<Placeholder className="right-pricebox" />

							<Placeholder className="right-installmentinfo" />

							<hr className="right-seperator"></hr>

							<div className="right-opts">
								<div className="opts-head">
									<Placeholder className="opts-title" />
									<Placeholder className="opts-btn" />
								</div>
								<ul className="opts-list">
									<li className="list-opt">
										<Placeholder className="opt-btn" />
									</li>
									<li className="list-opt">
										<Placeholder className="opt-btn" />
									</li>
									<li className="list-opt">
										<Placeholder className="opt-btn" />
									</li>
									<li className="list-opt">
										<Placeholder className="opt-btn" />
									</li>
									<li className="list-opt">
										<Placeholder className="opt-btn" />
									</li>
								</ul>
							</div>

							<hr className="right-seperator"></hr>

							<div className="right-opts">
								<div className="opts-head">
									<Placeholder className="opts-title" />
								</div>
								<ul className="opts-list">
									<li className="list-opt">
										<Placeholder className="opt-btn kind" />
									</li>
									<li className="list-opt">
										<Placeholder className="opt-btn kind" />
									</li>
								</ul>
							</div>

							<hr className="right-seperator"></hr>

							<Placeholder className="right-addbtn" />

							<Placeholder className="right-cargodate" />

							<div className="right-features">
								<Placeholder className="features-feature" />
								<Placeholder className="features-feature" />
							</div>

							<hr className="right-seperator"></hr>

							<div className="right-controls">
								<Placeholder className="controls-btn" />
								<Placeholder className="controls-btn" />
								<Placeholder className="controls-btn" />
								
							</div>

							<Placeholder className="right-share" />
						</div>
					</>
				}
			</div>
		</main>
	)
}

const ProductFeaturedComment = ({ className, product, mobile, onScrollToComments }) => {
	return (
		<div className={`info-featuredcomment ${className}`}>
			{mobile &&	
				<div className="featuredcomment-sum">
					<strong className="sum-title">Ürün Yorumları ({product.comment_count})</strong>
					<button
						onClick={() => { onScrollToComments(); }}
						className="sum-cta">
						Tümünü Gör
					</button>
				</div>
			}
			<div className="featuredcomment-head">
				<div className="head-left">
					<RatingBox rating={product.featured_comment.score} className="featuredcomment-rating" />
					<span className="featuredcomment-datetime">
						{formatDate(product.featured_comment.created_at)}
					</span>
				</div>
				<span className="featuredcomment-author">{product.featured_comment.name} {product.featured_comment.surname}</span>
			</div>

			<div className="featuredcomment-text">
				{product.featured_comment.comment}
			</div>

			{!mobile &&
				<button
					onClick={() => { onScrollToComments(); }}
					className="featuredcomment-cta">
					Tüm Yorumlar ({product.comment_count})
				</button>
			}
		</div>
	)
}

const ProductDescription = ({ product }) => {
	const _mounted = useRef(false);

	const productID = product ? product.id : false;
	const [productProperties, setProductProperties] = useState(false);

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, []);

	useEffect(() => {
		if(!productProperties || (productProperties && productID !== productProperties.id)) {
			setProductProperties({ id: productID, props: false });
			getProductProperties(productID).then((payload) => {
				if(_mounted.current) {
					setProductProperties({ id: productID, props: payload });
				}
			})
		}
	}, [productID, productProperties])

	if(product) {
		return (
			<>
				{product.description && product.description.length !== 0 &&
					<div
						className="info-description wysiwyg"
						dangerouslySetInnerHTML={{__html: product.description}}>
					</div>
				}
				<div className="info-properties">
					<h3 className="properties-title">Ürün Özellikleri</h3>
					<table className="properties-table table">
						<tbody>
							<tr>
								<th>Ürün Kodu</th>
								<td>{product.barcode}</td>
							</tr>
							{(product.brand && product.brand.length > 0) &&
								<tr>
									<th>Marka</th>
									<td>{product.brand}</td>
								</tr>
							}
							{product.gender_name &&
								<tr>
									<th>Cinsiyet</th>
									<td>{product.gender_name}</td>
								</tr>
							}
							{(product.madein && product.madein.length > 0) &&
								<tr>
									<th>Üretim Yeri</th>
									<td>{product.madein}</td>
								</tr>
							}
							{product.refund_status === 0 &&
								<tr>
									<th>İade</th>
									<td>Ürün iade edilemez.</td>
								</tr>
							}

							{/* {!(product.installment_status === 1 && product.installment_count > 1) &&
								<tr>
									<th>Taksit</th>
									<td>Taksit yapılamaz.</td>
								</tr>
							} */}
							{productProperties.props && productProperties.props.length > 0 &&
								<>
									{productProperties.props.map((prop, nth) => (
										<tr key={nth}>
											<th>{prop.property}</th>
											<td>{prop.name}</td>
										</tr>
									))}
								</>
							}
							<tr>
								<th>Barkod</th>
								<td>{product.product_code}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</>
		)
	}
	else {
		return false;
	}
}

const ProductComments = ({product, userData, comments, onCommentsChange}) => {
	const _mounted = useRef(false);
	const { openModal } = useModals();

	useEffect(() => {
		_mounted.current = true;

		return () => {
			_mounted.current = false;
		}
	}, []);

	useEffect(() => {
		onCommentsChange(false);
		productServices.getProductComments(product.id).then((payload) => {
			if(_mounted.current) {
				onCommentsChange(payload);
			}
		})
	}, [product, onCommentsChange])
	return (
		<div className="info-comments">
			{product.comment_count !== 0 &&
				<div className="comments-sum">
					<div className="sum-left">
						<strong className="sum-title">Genel Değerlendirme</strong>

						<div className="sum-rating">
							<span className="rating-score">{product.comment_average_string}</span>
							<RatingBox big className="rating-stars" rating={product.comment_average} />
							<span className="rating-comments">({product.comment_count} Yorum)</span>
						</div>
					</div>
					<Btn
						onClick={() => {
							if(userData) {
								openModal('product-comment', {
									product: product,
								})
							}
							else {
								openModal('login-message', {
									message: 'Ürün hakkında değerlendirme yapabilmek için üye girişi yapmalısınız.'
								})
							}
						}}
						className="gray-darker">
						Ürünü Değerlendir
					</Btn>
				</div>
			}
			<div className="loader-container">
				<Loader loading={!comments} message="Ürün yorumları getiriliyor..." />
				{comments &&
					<>
						{comments.length !== 0 ?
							<>
								<h3 className="comments-title">Ürün Yorumları</h3>
								<ul className="comments-list">
									{comments.map((comment, nth) => (
										<li className="list-comment" key={nth}>
											<div className="comment-info">
												<RatingBox className="info-rating" rating={comment.score} />
												<span className="info-date">
													{formatDate(comment.created_at)}
												</span>
												<span className="info-poster">
													{comment.name} {comment.surname}
												</span>
											</div>
											<div className="comment-message wysiwyg">
												{comment.comment}
											</div>
										</li>
									))}
								</ul>
							</>
							:
							<EmptyMessage lined icon={false} className="comments-emptymessage">
								<p>Bu ürün hakkında hiç değerlendirme yapılmamış.</p>
								{(userData && product.comment_available) &&
									<Btn
										onClick={() => {
											openModal('product-comment', {
												product: product,
											})
										}}
										className="emptymessage-cta text">İlk Değerlendirmeyi Yap</Btn>
								}
							</EmptyMessage>
						}
					</>
				}
			</div>
		</div>
	)
}

const ProductReturnTerms = ({ data }) => {
	return (
		<div
			className="info-returnterms wysiwyg"
			dangerouslySetInnerHTML={{__html: data?.description ?? 'İade şartları bulunmuyor.'}}>
		</div>
	)
}

const ProductCampaigns = ({ campaigns }) => {
	const { openModal } = useModals();
	
	return (
		<div className="info-campaigns loader-container">
			<Loader loading={!campaigns} />
			{campaigns && campaigns.length > 0 &&
				<ul className="campaigns-list">
					{campaigns.map((campaign, nth) => (
						<li key={nth} className="list-campaign">
							<button className="campaign-button" onClick={() => { openModal('campaign', { campaign: campaign }) }}>
								<Img
									cover
									src={campaign.image}
									className="campaign-image" />
								<strong className="campaign-title">{campaign.name}</strong>
								<p className="campaign-description">{campaign.description}</p>

								<p className="campaign-ctawrap">Kampanya detayı <Icon name="arrow-right" /></p>
							</button>
						</li>
					))}
				</ul>
			}
		</div>
	)
}

const ProductInstallments = ({product}) => {
	return (
		<div className="info-installments">
			{(product.installment_status === 1 && product.installment_count > 1) ?
				<ProductInstallmentOptions product={product} />
				:
				<div className="wysiwyg">
					<p>
						<strong>
							Önemli Not
						</strong>
					</p>
					<p>
						Taksitli alışveriş için sipariş tutarınız 100 TL ve üzerinde olmalıdır.
					</p>
					<p>
						Yürürlükteki mevzuat gereğince, telekomünikasyon, kuyum, doğrudan pazarlama, yurtdışı harcamalar, yemek, gıda, kozmetik, ofis malzemesi, hediye kart ve hediye çeki gibi somut mal veya hizmet içermeyen ürünlere ilişkin alışverişlerde taksit uygulanamamaktadır.
					</p>
				</div>
			}
		</div>
	)
}

export default ProductDetail

ProductDetail.getServerProps = ({ match, routes }) => {
	return new Promise((resolve) => {
		const { paramProductID, paramSerialID } = {
			paramProductID: match.params.id ? parseInt(match.params.id) : false,
			paramSerialID: match.params.serialID ? parseInt(match.params.serialID) : false,
		}

		getProduct(paramProductID).then((productData) => {
			const processedPayload = processProduct(productData);
			const kind = getInitialKind(processedPayload);
			const serial = getInitialSerial(kind, paramSerialID);

			if(kind) {
				const { params: kindLinkParams } = getProductLinkParams(kind, serial);

				if(kindLinkParams.slug !== match.params.slug || (serial && match.params.serialSlug && match.params.serialSlug !== kindLinkParams.serialSlug)) {
					resolve({
						redirect: {
							key: 'productDetail',
							params: {
								...match.params,
								slug: kindLinkParams.slug,
								...(() => {
									if(match.params.serialSlug && match.params.serialSlug) {
										return {
											serialSlug: kindLinkParams.serialSlug,
										}
									}
									return {}
								})()
							}
						}
					});
				}
				else {
					Promise.all([
						getSchema(paramProductID)
					]).then(([productSchema]) => {
						const ssrGetRoutePath = (routeKey, params, { parseQueryParams, queryParamKey } = {}) => {
							return getRoutePathFunction({
								routes,
								routeKey,
								params,
								parseQueryParams,
								queryParamKey,
							});
						}
	
						resolve({
							pageProps: {
								product: processedPayload,
								productSchema,
							},
							head: parseHead(kind, serial, processedPayload, productSchema, ssrGetRoutePath),
						})
					})
				}
			}
			else {
				resolve({
					status: 404,
					notFound: true,
				})
			}
		}).catch((rawStatus) => {
			const status = typeof rawStatus === 'number' ? rawStatus : 500;
			resolve({
				status: status,
				notFound: status === 404,
				error: status !== 404,
			})
		})
	});
}