import '/assets/styles/sections/site-features.scss';
import config from '/config';
import PropTypes from 'prop-types';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';

// Context
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';

// Static Assets
import image_feature_freecargo from '/assets/images/icons/features/freecargo.svg';
import image_feature_installment from '/assets/images/icons/features/installment.svg';
import image_feature_return from '/assets/images/icons/features/return.svg';
import image_feature_storereturn from '/assets/images/icons/features/storereturn.svg';
import image_feature_assembly from '/assets/images/icons/features/assembly.svg';
import image_feature_genuine from '/assets/images/icons/features/genuine.svg';
import image_feature_livesupport from '/assets/images/icons/features/livesupport.svg';

const SiteFeatures = ({ className }) => {
	const { sysParameters } = useGlobalState();

	return (
		<section className={'section site-features' + (className ? ' ' + className : '')}>
			<div className="wrapper narrower features-wrap">
				<ul className="features-list">
					{!!sysParameters?.['free_cargo_price_limit'] &&
						<li className="list-feature">
							<Img
								className="feature-icon"
								src={image_feature_freecargo}
								alt="Ücretsiz kargo" />
							<p className="feature-text">
								{sysParameters['free_cargo_price_limit']?.description} TL ve üzeri <br />ücretsiz kargo
							</p>
						</li>
					}
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_installment}
							alt="Taksit" />
						<p className="feature-text">
							Peşin fiyatına <br />taksit imkânı
						</p>
					</li>
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_return}
							alt="Taksit" />
						<p className="feature-text">
							14 gün içinde <br />iade hakkı
						</p>
					</li>
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_storereturn}
							alt="Taksit" />
						<p className="feature-text">
							Mağazadan <br />değiştir
						</p>
					</li>
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_assembly}
							alt="Taksit" />
						<p className="feature-text">
							Oto koltuklarına <br />mağazada ücretsiz montaj
						</p>
					</li>
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_genuine}
							alt="Orijinal ürün garantisi" />
						<p className="feature-text">
							Orijinal ürün <br />garantisi
						</p>
					</li>
					<li className="list-feature">
						<Img
							className="feature-icon"
							src={image_feature_livesupport}
							alt="7/24 canlı destek" />
						<p className="feature-text">
							7/24 canlı <br className="only-mobile" />destek <br /><a className="link" href={config.callCenterPhoneHref}>{config.callCenterPhone}</a>
						</p>
					</li>
				</ul>
			</div>
		</section>
	)
}

SiteFeatures.propTypes = {
	className: PropTypes.string,
}

export default SiteFeatures;