import '/assets/styles/partials/product-installment-options.scss';

import { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

// Partials
import Img from '@wearetla/tla-essentials-tools/partials/img';
import Loader from '/views/partials/loader'

// Services
import productServices from '/services/product'

// Data
import bankLogos from '/data/bank-logos'
import cardBrandLogos from '/data/card-brand-logos'

const ProductInstallmentOptions = ({product}) => {
	const [installments, setInstallments] = useState(false);
	
	useEffect(() => {
		productServices.getProductInstallments(product).then((payload) => {
			let expandedInstallments = [];

			for(const bankKey in payload.installments) {
				expandedInstallments = [...expandedInstallments, ...payload.installments[bankKey]];
			}
			setInstallments(expandedInstallments);
		}).catch(() => {
		})
	}, [product]);

	return (
		<div className="product-installment-options loader-container">
			<Loader loading={!installments} />
			{installments &&
				<>
					<ul className="options-installments">
						{installments.map((group => {
							const bankLogo = cardBrandLogos[group.installmentTable[0].bank_card_logo] ? cardBrandLogos[group.installmentTable[0].bank_card_logo] : (bankLogos[group.bank] ? bankLogos[group.bank] : false)
							return (
								<li className="installments-group" key={group.key}>
									<div className="group-head">
										<Img
											alt={group.installmentTable[0].bank_card_name}
											title={group.installmentTable[0].bank_card_name}
											className="head-logo"
											src={bankLogo ? bankLogo : cardBrandLogos['default']} />
										{!bankLogo &&
											<strong className="head-title">{group.installmentTable[0].bank_card_name}</strong>
										}
									</div>

									<ul className="group-opts">
										{group.installmentTable.map((opt => (
											<li key={opt.id} className="opts-opt">
												<span>
													{opt.installment !== 1 ? 
														<>
															{opt.installment_amount.original_str} TL x {opt.installment_calculate} {(opt.installment_text && opt.installment_text.length > 0 && opt.installment_text !== opt.installment_calculate.toString()) && <> ({opt.installment_text})</>}
														</>
														:
														<>
															{opt.description}
														</>
													}

													{opt.installment > 1 && opt.ratio === '0' && <span className="opt-promo">Peşin fiyatına</span>}
												</span>
												<strong>{opt.price.original_str} TL</strong>
											</li>
										)))}
									</ul>
								</li>
							)
						}))}
					</ul>
				</>
			}
		</div>
	)
}

ProductInstallmentOptions.propTypes = {
	product: PropTypes.oneOfType([ PropTypes.object, PropTypes.oneOf([false]) ]),
}

export default ProductInstallmentOptions