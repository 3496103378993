import image_default from '/assets/images/payment/card-brand-logos/default.svg'
import image_bonus from '/assets/images/payment/card-brand-logos/bonus.svg'
import image_axess from '/assets/images/payment/card-brand-logos/axess.svg'
import image_cardfinans from '/assets/images/payment/card-brand-logos/cardfinans.svg'
import image_paraf from '/assets/images/payment/card-brand-logos/paraf.svg'
import image_maximum from '/assets/images/payment/card-brand-logos/maximum.svg'
import image_world from '/assets/images/payment/card-brand-logos/world.svg'
import image_vakifbank from '/assets/images/payment/card-brand-logos/vakifbank.svg'

const cardBrandLogos = {
	'default': image_default,
	'bonus': image_bonus,
	'bonus-card': image_bonus,
	'axess': image_axess,
	'axess-card': image_axess,
	'card-finans': image_cardfinans,
	'cardfinans': image_cardfinans,
	'paraf': image_paraf,
	'maximum': image_maximum,
	'maximum-card': image_maximum,
	'world': image_world,
	'world-card': image_world,
	'vakifbank': image_vakifbank,
	'vakifbank-card': image_vakifbank,
}

export default cardBrandLogos
